@import './variables.scss';

@font-face {
  font-family: ABBVoice;
  src: url(../assets/fonts/ABBvoice_W_Lt.eot);
  src:
    url(../assets/fonts/ABBvoice_W_Lt.eot?#iefix) format('embedded-opentype'),
    url(../assets/fonts/ABBvoice_W_Lt.woff2) format('woff2'),
    url(../assets/fonts/ABBvoice_W_Lt.woff) format('woff'),
    url(../assets/fonts/ABBvoice_W_Lt.ttf) format('truetype');
  font-weight: $font-light;
  font-style: normal;
}

@font-face {
  font-family: ABBVoice;
  src: url(../assets/fonts/ABBvoice_W_Rg.eot);
  src:
    url(../assets/fonts/ABBvoice_W_Rg.eot?#iefix) format('embedded-opentype'),
    url(../assets/fonts/ABBvoice_W_Rg.woff2) format('woff2'),
    url(../assets/fonts/ABBvoice_W_Rg.woff) format('woff'),
    url(../assets/fonts/ABBvoice_W_Rg.ttf) format('truetype');
  font-weight: $font-regular;
  font-style: normal;
}

@font-face {
  font-family: ABBVoice;
  src: url(../assets/fonts/ABBvoice_W_Md.eot);
  src:
    url(../assets/fonts/ABBvoice_W_Md.eot?#iefix) format('embedded-opentype'),
    url(../assets/fonts/ABBvoice_W_Md.woff2) format('woff2'),
    url(../assets/fonts/ABBvoice_W_Md.woff) format('woff'),
    url(../assets/fonts/ABBvoice_W_Md.ttf) format('truetype');
  font-weight: $font-medium;
  font-style: normal;
}

@font-face {
  font-family: 'ABBvoice Display';
  src:
    url(../assets/fonts/ABBvoiceDisplay_W_SBd.woff2) format('woff2'),
    url(../assets/fonts/ABBvoiceDisplay_W_SBd.woff) format('woff'),
    url(../assets/fonts/ABBvoiceDisplay_SBd.ttf) format('truetype');
  font-weight: $font-bold;
  font-style: normal;
}

@font-face {
  font-family: ABBVoice;
  src: url(../assets/fonts/ABBvoice_W_Bd.eot);
  src:
    url(../assets/fonts/ABBvoice_W_Bd.eot) format('embedded-opentype'),
    url(../assets/fonts/ABBvoice_W_Bd.woff2) format('woff2'),
    url(../assets/fonts/ABBvoice_W_Bd.woff) format('woff'),
    url(../assets/fonts/ABBvoice_W_Bd.ttf) format('truetype');
  font-weight: $font-bold;
  font-style: normal;
}
